import React, { useMemo, useState } from "react";
import { Layout, Menu, Breadcrumb, Button } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const { Header, Content, Footer, Sider } = Layout;

function Dashboard(props) {
  const { isAdmin } = useSelector((state) => state.auth);
  console.log(isAdmin, "roles");
  // console.log(`Dashboard`, isAdmin);
  const [openSubMenu, setOpenSubMenu] = useState([]);
  const navigate = useNavigate();

  const handleSubMenuOpenChange = (keys) => {
    setOpenSubMenu(keys);
  };
  const handleLogout = () => {
    // Dispatch the signOut action when the logout button is clicked
    navigate("/signout"); // Navigate to the SignOut page
  };

  const renderSubMenuItems = (subMenuItems) => {
    return subMenuItems
      .filter((item) => {
        return (
          isAdmin !== "moderator" ||
          !item.name.toLowerCase().includes("add") ||
          !item.name.toLowerCase().includes("update")
        );
      })
      .map((item) => (
        <Menu.Item key={item.key}>
          <a href={item.link}>{item.name}</a>
        </Menu.Item>
      ));
  };

  const menuData = [
    {
      key: "1",
      name: "Dashboard",
      subMenus: [],
      link: "/dashboard",
    },
    // {
    //   key: "2",
    //   name: "Helper",
    //   subMenus: [
    //     {
    //       key: "2-1",
    //       name: "Get InitialData",
    //       link: "/helper/get-initialData",
    //     },
    //     {
    //       key: "2-2",
    //       name: "Update InitialData",
    //       link: "/helper/update-initialData",
    //     },
    //     { key: "2-3", name: "Get RpcUrlV3", link: "/helper/get-rpcUrlV3" },
    //     {
    //       key: "2-4",
    //       name: "Update RpcUrlV3",
    //       link: "/helper/update-rpcUrlV3",
    //     },
    //   ],
    //   link: "/helper",
    // },
    // {
    //   key: "3",
    //   name: "Giveaway",
    //   subMenus: [
    //     {
    //       key: "3-1",
    //       name: "Add Rewards",
    //       link: "/giveaway/add-rewards",
    //     },
    //     {
    //       key: "3-2",
    //       name: "Get User Rewards",
    //       link: "/giveaway/get-rewards",
    //     },
    //     {
    //       key: "3-3",
    //       name: "Update The Rewards",
    //       link: "/giveaway/update-rewards",
    //     },
    //     {
    //       key: "3-4",
    //       name: "Add Rewards For Mutilple Users",
    //       link: "/giveaway/add-rewards/multiple",
    //     },
    //   ],
    //   link: "/giveaway",
    // },
    // {
    //   key: "4",
    //   name: "Referral",
    //   subMenus: [
    //     { key: "4-1", name: "Get User Details", link: "/referral/userDetails" },
    //     { key: "4-2", name: "Add Points", link: "/referral/add-points" },
    //     {
    //       key: "4-3",
    //       name: "Update Points",
    //       link: "/referral/update-points",
    //     },
    //     {
    //       key: "4-4",
    //       name: "Add points multiple",
    //       link: "/referral/add-points/multiple",
    //     },
    //   ],
    //   link: "/referral",
    // },
    // {
    //   key: "5",
    //   name: "Chart",
    //   subMenus: [
    //     {
    //       key: "5-1",
    //       name: "Get token Info From Coingecko",
    //       link: "/chart/get-token-info-from-coingecko",
    //     },
    //     {
    //       key: "5-2",
    //       name: "Get token Info From Chart",
    //       link: "/chart/get-token-info-from-charts-db",
    //     },
    //     {
    //       key: "5-3",
    //       name: "Add Token In Charts Db",
    //       link: "/chart/add-token-in-charts-db",
    //     },
    //     {
    //       key: "5-4",
    //       name: "Add Token by coingecko-id ",
    //       link: "/chart/add-token-by-coingecko-id",
    //     },
    //     {
    //       key: "5-5",
    //       name: "Delete Token From Charts Db",
    //       link: "/chart/delete-token-from-charts-db",
    //     },
    //   ],
    //   link: "/chart",
    // },
    // {
    //   key: "6",
    //   name: "User",
    //   subMenus: [
    //     { key: "6-1", name: "NFTBalance", link: "/user/nftbalance" },
    //     {
    //       key: "6-2",
    //       name: "User-Balalnce-Ankr",
    //       link: "/user/userbalance/ankr",
    //     },
    //     { key: "6-3", name: "User-Balalnce", link: "/user/userbalance" },
    //     { key: "6-4", name: "Search-User", link: "/user/search" },
    //   ],
    //   link: "/user",
    // },

    {
      key: "2",
      name: "Notifiaction",
      subMenus: [
        { key: "7-1", name: "Send To All", link: "/notifiaction/send-to-all" },
        {
          key: "7-2",
          name: "Send Test Notification",
          link: "/notifiaction/send-test-notification",
        },
      ],
      link: "/notifiaction",
    },
  ];
  const filteredMenuData = menuData
    .map((menuItem) => {
      // Filter subMenus based on the condition that isAdmin is not "moderator"
      const filteredSubMenus = menuItem.subMenus.filter((subMenu) => {
        const lowerCaseName = subMenu.name.toLowerCase();
  
        return (
          !lowerCaseName.includes("add") &&
          !lowerCaseName.includes("update") &&
          !lowerCaseName.includes("delete") &&
          !lowerCaseName.includes("send")
        );
      });

      // If there are filtered subMenus, return the updated menuItem, otherwise, return null
      return filteredSubMenus.length > 0
        ? { ...menuItem, subMenus: filteredSubMenus }
        : null;
    })
    .filter(Boolean); // Remove any null values from the array

  console.log(filteredMenuData, "updatedMenuData");
  console.log(isAdmin,"dash");
  const dataToRender = isAdmin === "moderator" ? filteredMenuData : menuData;

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={300}
        theme="dark"
        collapsible
        collapsed={false}
        onCollapse={() => {}}
        style={{
          overflow: "auto",
          height: "100vh",
        }}
      >
        <Menu
          mode="inline"
          theme="dark"
          inlineCollapsed={false}
          openKeys={openSubMenu}
          onOpenChange={handleSubMenuOpenChange}
        >
          {dataToRender.map((menuItem) => (
            <Menu.SubMenu key={menuItem.key} title={menuItem.name}>
              {menuItem.subMenus.map((item) => (
                <Menu.Item key={item.key}>
                  <a href={item.link}>{item.name}</a>
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}
        </Menu>
      </Sider>
      <Layout style={{ marginLeft: "40px" }}>
        <Content style={{ margin: "24px 16px", padding: 24, minHeight: 280 }}>
          <Outlet />
        </Content>
      </Layout>
      <Footer style={{ textAlign: "center" }}>
        <Button type="primary" onClick={handleLogout}>
          Logout
        </Button>
      </Footer>
    </Layout>
  );
}

export default Dashboard;
