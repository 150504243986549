import { REQUEST_TYPE } from '../../../constants';
import { doRequest } from '../../network';

export const sendPushNotificationsToAll = async (title, body, data) => {
  try {
    const requestData = {
      title,
      body,
      data: JSON.parse(data), // Convert the data object to a JSON string
    };
    const response = await doRequest({
      method: REQUEST_TYPE.POST, // Change to POST
      url: '/admin_panel/v1/send-push-notifications',
      data: {
        ...requestData, // Double stringify the data property
      },
    });

    const { data: responseData, status } = response || {};
    console.log(status,"status")
    return response; // Correct the variable name to avoid conflict
  } catch (err) {
    return { status: false };
  }
};

export const sendPushNotificationsToTest = async (walletAddresses, title, body, data) => {
  try {
    const requestData = {
      walletAddresses,
      title,
      body,
      data:JSON.parse(data),
    };
    console.log(requestData);
    
    const response = await doRequest({
      method: REQUEST_TYPE.POST,
      url: '/admin_panel/v1/send-push-notifications/test',
      data: requestData,
    });

    const { data: responseData, status } = response || {};
    return response;
  } catch (err) {
    return { status: false };
  }
};
