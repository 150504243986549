import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

const Global = lazy(() => import("./Global"));
const Auth = lazy(() => import("./Auth"));

export default function Routes() {
  const { authenticated } = useSelector((state) => state.auth);
  console.log(authenticated, "authenticated");

  const loadingState = () => {
    return (
      <div className="flex vh100 bg-dark-blue center column">
        <p>Plena Finance Admin Panel</p>
      </div>
    );
  };
  if (authenticated !== true && authenticated !== false ) {
    return this.loadingState();
  }
  const auth = JSON.parse(localStorage.getItem("auth"));

  const token = auth?.token;
  // console.log(token,"auth");
  return (
    <Suspense fallback={loadingState}>
      {/* <Auth />  */}
      <>{!token ? <Auth /> : <Global />}</>
    </Suspense>
  );
}
