import * as _ from "lodash";
import { doRequest } from "../../helper/network";
import { REQUEST_TYPE } from "../../constants";

const initialState = {
  authenticated: false,
  authenticatedUser: null,
  token: null,
  isAdmin: "moderator",
};

const SIGNING_SUCCESSFUL = "SIGNING_SUCCESSFUL";
export const LOGOUT_SUCCESSFUL = "LOGOUT_SUCCESSFUL";

const auth = JSON.parse(localStorage.getItem("auth"));

const newState = {
  token: auth?.token || null,
  authenticatedUser: auth || null,
  authenticated: !!auth?.token,
  isAdmin: auth?.roles,
};
const initialisedState = {
  ...initialState,
  ...newState,
};

export const signIn = (requestData) => {
  return async (dispatch) => {
    try {
      const response = await doRequest({
        method: REQUEST_TYPE.POST,
        data: requestData,
        url: "/admin_panel/v1/auth/authenticate",
      });

      const { data = {}, success = false, message = "" } = response;
      console.log("from sign-in", data);

      if (success && !_.isEmpty(data)) {
        dispatch({
          type: SIGNING_SUCCESSFUL,
          payload: data,
        });

        return { success: true, data };
      } else {
        return { success: false, error: message };
      }
    } catch (err) {
      // Handle any errors during the request
      console.error("Error during sign-in:", err);
      return { success: false, error: err.message };
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    let response = {};
    const { token, user_name, role } = JSON.parse(localStorage.getItem("auth"));
    console.log("Signing out", { token, user_name });
    try {
      response = await doRequest({
        method: REQUEST_TYPE.POST,
        url: "/admin_panel/v1/auth/logout",
        data: { accessToken: token, user_name },
      });
      console.log("auth checking  signout ...", response);
      const { success = false, message = "" } = response;
      if (success) {
        dispatch({
          type: LOGOUT_SUCCESSFUL,
          payload: response,
        });
      } else {
        return { success: false };
      }
    } catch (err) {
      return { success: false };
    }
    return response;
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialisedState, action = {}) => {
  const { type, payload = {} } = action;

  switch (type) {
    case SIGNING_SUCCESSFUL:
      console.log("auth checking123", payload);
      localStorage.setItem("auth", JSON.stringify(payload));

      return {
        // ...state,
        // loading: false,
        // isError: false,
        // error: "",
        authenticated: true,
        authenticatedUser: payload || null,
        isAdmin: payload?.authenticatedUser?.roles || "moderator",
        token: payload.token || null,
      };
    case LOGOUT_SUCCESSFUL:
      localStorage.setItem("auth", null);
      return {
        authenticated: false,
        authenticatedUser: null,
      };
    default:
      return state;
  }
};
