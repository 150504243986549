export const PATH = {
  DASHBOARD: '/',
  HOME: 'HOME',
  SIGNIN: '/signin',
  LOGOUT: '/logout',
};

export const REQUEST_TYPE = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

export const USER_RIGHTS = {
  GET_DATA_FROM_CHARTS: 'GET_DATA_FROM_CHARTS',
};
