import axios from "axios";
import { PATH } from "../../constants";
import { isEmpty } from "lodash";
import qs from "qs";
import { message } from "antd";

const isAdmin = (isAdmin) => {
  // Implement your logic to check if the user is an admin
  // For example, if you have a role property in headers, you can check headers.role === 'admin'
  return isAdmin === "admin";
};

export const doRequest = async (requestData) => {
  const defaultHeader = {};

  // timeout specifies the number of milliseconds before the request times out.
  // If the request takes longer than timeout, the request will be aborted.
  //const timeout = 5000; // default is 0 (no timeout)

  // xsrfCookieName is the name of the cookie to use as a value for xsrf token
  const xsrfCookieName = "XSRF-TOKEN"; // default

  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  const xsrfHeaderName = "X-XSRF-TOKEN"; // default
  // const token = cookies.get('token');
  const auth = JSON.parse(localStorage.getItem("auth"));
  const token = auth ? auth.token : null;
  const isAdmin = auth ? auth.roles : "moderator";
  console.log(isAdmin, "from do request");

  const {
    headers = { Authorization: token ? `Bearer ${token}` : null },
    method = "get",
    url = "",
    baseURL = process.env.REACT_APP_API_HOST,
    params = {},
    data = {},
    onUploadProgress,
  } = requestData;
  //create request config according to data

  if ((method === "post" || method === "delete") && !isAdmin(isAdmin)) {
    const errorMessage = "You do not have permission to perform this action.";

    message.error(errorMessage);

    return { data: null, message: errorMessage };
  }
  const requestConfig = {
    headers: Object.assign(defaultHeader, headers),
    method,
    url,
    baseURL,
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: "comma" });
    },
    data,
    // xsrfCookieName,
    // xsrfHeaderName,
    onUploadProgress,
  };

  try {
    const response = await axios(requestConfig);
    return response.data;
  } catch (error) {
    console.log("error repsonse  ", error);
    if (error?.response?.status === 401) {
      window.location.replace(PATH.LOGOUT);
    }
    const { response: { data = {} } = {} } = error || {};
    return isEmpty(data) ? { data, message: error.message } : data;
  }
};
