import React, { useEffect, useState } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { Chart } from './helper/URLS';
import Routes from './routes';
import store from './store';
import Dashboard from './Components/Dashboard';

function App() {
  // const testAPI = async () => {
  //   const result = await Chart.getChartDetails();
  //   console.log('chart result ', result);
  // };
  // useEffect(() => {
  //   testAPI();
  // }, []);

  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
}

export default App;
